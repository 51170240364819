var columns = [{
  title: '组合序号',
  dataIndex: 'combinationid',
  key: 'combinationid',
  // width: '7%',
  scopedSlots: {
    customRender: 'combinationid'
  }
}, {
  title: '组合名称',
  dataIndex: 'combinationname',
  key: 'combinationname',
  // width: '11%',
  scopedSlots: {
    customRender: 'combinationname'
  }
}, {
  title: '商品类目',
  dataIndex: 'categoryname',
  key: 'categoryname',
  // width: '7%',
  scopedSlots: {
    customRender: 'categoryname'
  }
}, {
  title: '品牌名称',
  dataIndex: 'brandname',
  key: 'brandname',
  // width: '7%',
  scopedSlots: {
    customRender: 'brandname'
  }
},
// {
//     title: '系列名称',
//     dataIndex: 'seriename',
//     key: 'seriename',
//     // width: '9%',
//     scopedSlots: {
//         customRender: 'seriename'
//     }
// },
{
  title: '货号',
  dataIndex: 'sku',
  key: 'sku',
  // width: '15%',
  scopedSlots: {
    customRender: 'sku'
  }
}, {
  title: '商品图片',
  dataIndex: 'imgurls',
  key: 'imgurls',
  // width: '9%',
  scopedSlots: {
    customRender: 'imgurls'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  key: 'operation',
  // width: '8%',
  scopedSlots: {
    customRender: 'operation'
  }
}
// {
//     title: '操作',
//     key: 'action',
//     dataIndex: 'action',
//     fixed: "right",
//     width: 200,
//     scopedSlots: {
//         customRender: 'action'
//     }
// }
];

var addcolumns = [{
  title: 'ID',
  dataIndex: 'id',
  key: 'id',
  width: '20%',
  scopedSlots: {
    customRender: 'id'
  }
}, {
  title: '商品名称',
  dataIndex: 'name',
  key: 'name',
  width: '20%',
  scopedSlots: {
    customRender: 'name'
  }
}, {
  title: '货号',
  dataIndex: 'sku',
  key: 'sku',
  width: '20%',
  scopedSlots: {
    customRender: 'sku'
  }
}, {
  title: '类目图片',
  dataIndex: 'imgurls',
  key: 'imgurls',
  width: '20%',
  scopedSlots: {
    customRender: 'imgurls'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  key: 'operation',
  width: '20%',
  scopedSlots: {
    customRender: 'operation'
  }
}];
export { columns, addcolumns };