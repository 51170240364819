import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    staticStyle: {
      "overflow-x": "scroll"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.createForm,
      layout: "inline"
    }
  }, [_c("FormItems", {
    attrs: {
      searchKeys: _vm.searchKeys,
      getDataWithKey: _vm.getDataWithKey,
      disabledDate: _vm.disabledDate
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c("a-button", {
          staticStyle: {
            "margin-bottom": "10px"
          },
          attrs: {
            type: "primary",
            size: "large"
          },
          on: {
            click: _vm.handleSearch
          }
        }, [_vm._v("查询")]), _c("a-button", {
          staticStyle: {
            "margin-left": "20px",
            border: "0",
            background: "#f5f5f5"
          },
          attrs: {
            size: "large"
          },
          on: {
            click: _vm.handleReset
          }
        }, [_vm._v("重置")])];
      },
      proxy: true
    }])
  })], 1)], 1), _c("a-card", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["combination:btn:add"],
      expression: "['combination:btn:add']"
    }],
    staticStyle: {
      "margin-bottom": "24px"
    },
    attrs: {
      type: "primary",
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.addcombinationgoods("", 0);
      }
    }
  }, [_vm._v("新增")]), _c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      rowKey: function rowKey(it, i) {
        return it.id;
      },
      loading: _vm.loading
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (_ref) {
      var key = _ref.key;
      return {
        key: key,
        fn: function fn(text, record, index) {
          return [key == "combinationid" ? _c("span", [_c("span", [_vm._v(_vm._s(record.id))])]) : key == "combinationname" ? _c("span", [_c("span", [_vm._v(_vm._s(record.name))])]) : key == "categoryname" && record.products ? _c("span", _vm._l(record.products, function (items, index) {
            return _c("span", {
              key: index,
              staticClass: "tbs"
            }, [_vm._v(" " + _vm._s(items.parentcategoryname + "-" + items.categoryname || "---") + " "), _c("br")]);
          }), 0) : key == "brandname" && record.products ? _c("span", _vm._l(record.products, function (items, index) {
            return _c("span", {
              key: index,
              staticClass: "tbs"
            }, [_vm._v(" " + _vm._s(items.brandname || "---") + " "), _c("br")]);
          }), 0) : key == "seriename" && record.products ? _c("span", _vm._l(record.products, function (items, index) {
            return _c("span", {
              key: index,
              staticClass: "tbs"
            }, [_vm._v(" " + _vm._s(items.seriename || "---") + " "), _c("br")]);
          }), 0) : key == "sku" && record.products ? _c("span", _vm._l(record.products, function (items, index) {
            return _c("span", {
              key: index,
              staticClass: "tbs"
            }, [_vm._v(" " + _vm._s(items.sku || "---") + " "), _c("br")]);
          }), 0) : key == "imgurls" && record.products ? _c("span", _vm._l(record.products, function (items, index) {
            return _c("div", {
              key: index,
              staticStyle: {
                height: "95px",
                border: "1px white solid"
              }
            }, [items.imgurls ? _c("img", {
              staticClass: "product_img",
              staticStyle: {
                "margin-top": "15px"
              },
              attrs: {
                src: _vm.splitImg(items)
              },
              on: {
                click: function click($event) {
                  _vm.preview(_vm.splitImg(items));
                }
              }
            }) : _c("span", [_vm._v(" --- ")]), _c("br")]);
          }), 0) : key == "operation" ? _c("span", [_c("a", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["combination:btn:edit"],
              expression: "['combination:btn:edit']"
            }],
            on: {
              click: function click($event) {
                return _vm.addcombinationgoods(record, 1);
              }
            }
          }, [_vm._v("编辑组合")]), _c("span", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["combination:btn:delete"],
              expression: "['combination:btn:delete']"
            }]
          }, [_c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }), _c("a", {
            on: {
              click: function click($event) {
                return _vm.handleDelete(record.id, 0);
              }
            }
          }, [_vm._v("删除组合")])], 1)]) : _c("span", [_vm._v(_vm._s(_vm.filterRecordValue(record[key])))])];
        }
      };
    })], null, true)
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onShowSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1), _c("DelUpdate", {
    attrs: {
      tipsDesc: _vm.tipsDesc,
      isDelete: true,
      comVisible: _vm.isDeleteVisible
    },
    on: {
      cancle: _vm.handleCancle,
      submit: _vm.handleSubmit
    }
  }), _c("PreviewPic", {
    attrs: {
      isShowPic: _vm.previewVisible,
      previewImage: _vm.previewImage
    },
    on: {
      closePreviewpic: _vm.previewHandleCancel
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };