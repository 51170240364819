var searchKeys = [{
  key: "name",
  label: "组合名称",
  placeholder: "请输入",
  required: false,
  input: true
}, {
  key: "sku",
  label: "货号",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
var addsearchKeys = [{
  key: "wewe",
  label: "组合名称",
  placeholder: "请输入",
  required: false,
  rules: [{
    required: true,
    message: "请输入名称！"
  }],
  input: true
}, {
  key: "article",
  label: "货号",
  placeholder: "多个货号间以逗号隔开",
  required: false,
  rules: [],
  input: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys, addsearchKeys };